import { getCart } from '../api/shared-app-requests';
import { AppStorage } from './storage';
import { clearAssociatedProduct } from '../utils/cart';
import { clearDiscount } from '../../utils/discounts';

/*
 ** The trackingId must remain consistent throughout the ordering process for reliable monitoring.
 ** The trackingId is derived from the cart ID, which exhibits the following behavior:
 ** - Empty carts generate different cart IDs on each fetch
 ** - Once an item is added to the cart, the cart ID stabilizes and remains constant
 ** This stable cart ID is then used for tracking purposes and persisted to the session storage.
 */
export const TRACKING_KEY = 'candyrack-tracking';

/*
 ** We use the Singleton pattern to ensure a single instance within each source.
 ** However, as this code runs in two different sources (core Candy Rack and the shopify extension Product Page Placement), we effectively have two separate singletons.
 ** To maintain consistent cart token tracking across both sources, we use CustomEvent to synchronize the token.
 ** This prevents race conditions where both sources could create different carts almost simultaneously and
 ** ensures all tracking events reference the same cart token.
 ** CustomEvent provides cross-browser support (including IE) and enables communication between different sources
 ** running in the same window context.
 */
export default class TrackingManager {
  private static instance: TrackingManager | null = null;
  private cartToken: string | null;
  private storage: AppStorage = new AppStorage('sessionStorage');
  private isCurrentlyFetchingCartToken = false;
  private readonly cartTokenUpdatedEventName = 'cart-token-updated';
  private pppViteApiEndpoint?: string; // exists only in vite environment when initialised by PPP

  constructor() {
    this.cartToken = this.storage.getItem(TRACKING_KEY);

    // Listen for cart token updates from other instances (potentially even different sources from the shopify extensions)
    window.addEventListener(this.cartTokenUpdatedEventName, ((
      e: CustomEvent,
    ) => {
      if (!e.detail) {
        return;
      }
      if (e.detail.token) {
        this.cartToken = e.detail.token;
        this.storage.setItem(TRACKING_KEY, e.detail.token);
      } else if (e.detail.token === null) {
        this.storage.removeItem(TRACKING_KEY);
        this.cartToken = null;
      }
    }) as EventListener);
  }

  /*
   ** Singleton pattern, but doesn't cover the case we have this file imported from multiple sources
   ** For PPP we give the config, that contains the candyRackBaseApiUrl as set via vite.
   ** Please note that the process.env.REACT_APP_CUSTOMER_API_HOSTNAME exists only in CR environment, but not
   **for PPP, as PPP is using vite setup.
   */
  public static getInstance(pppEndpoint?: string): TrackingManager {
    if (!TrackingManager.instance) {
      TrackingManager.instance = new TrackingManager();
    }
    if (pppEndpoint) {
      TrackingManager.instance.pppViteApiEndpoint = pppEndpoint;
    }
    return TrackingManager.instance;
  }

  /**
   * Gets the API endpoint URL by determining the correct base URL from available sources.
   *
   * Handles two different runtime environments:
   * 1. Core Candy Rack (CR) - uses process.env.REACT_APP_CUSTOMER_API_HOSTNAME
   * 2. Product Page Placement (PPP) - uses Vite environment variables
   *
   * The method first checks for if the pppViteApiEntpoint is set (we are within PPP shopify
   * extension context), and if not we fallback to Candy Raack react scripts environment.
   *
   * @throws {Error} If no valid API hostname is set or found
   * @returns {string} The complete API endpoint URL
   * @private
   */
  public _getApiEndpoint(): string {
    let baseUrl;
    if (this.pppViteApiEndpoint) {
      baseUrl = this.pppViteApiEndpoint;
    } else {
      baseUrl = process.env.REACT_APP_CUSTOMER_API_HOSTNAME;
    }
    if (!baseUrl) {
      throw new Error('No API hostname configuration found');
    }
    return `${baseUrl}api/candyrack`;
  }

  /*
   ** Returns the token from the cart, and sets it as a storage TRACKING_KEY, if not already set.
   **
   */
  async _getCartToken(): Promise<string | null> {
    if (this.cartToken) {
      return this.cartToken;
    }

    if (this.isCurrentlyFetchingCartToken) {
      return new Promise((resolve) => {
        // Prevents duplicate cart creation by creating a promise that waits for the other source to finish
        // Sets up a one-time event listener that will resolve when the other source broadcasts the new token
        const handler = (e: CustomEvent) => {
          if (e.detail.token) {
            window.removeEventListener(
              this.cartTokenUpdatedEventName,
              handler as EventListener,
            );
            resolve(e.detail.token);
          }
        };
        window.addEventListener(
          this.cartTokenUpdatedEventName,
          handler as EventListener,
        );
      });
    }
    this.isCurrentlyFetchingCartToken = true;

    try {
      const { token } = await getCart();
      this.cartToken = token;
      this.storage.setItem(TRACKING_KEY, token);

      // Notify other source about the new token
      window.dispatchEvent(
        new CustomEvent(this.cartTokenUpdatedEventName, {
          detail: { token },
        }),
      );

      return token;
    } finally {
      this.isCurrentlyFetchingCartToken = false;
    }
  }

  /*
   ** We were tracking failed cleanUps for a period but it seems this happens
   ** quite often. We don't plan on doing something (at least for now) about it,
   ** as we don't have much info about the situation, no merchant complained about it and it seems to be mostly
   ** caused by the triggered fetch on local tests. Relevant discussion can be found in APPS-5744
   ** HINT: The cleanup needs time to run through. In situations the customer quickly navigates away
   ** from the checkout, the token may not be cleaned. In normal use case however it is ok.
   */
  async cleanUpCandyRackStorageTrackingInformation(): Promise<void> {
    if (window.Shopify.checkout) {
      // we are using the storage item instead of what is set in the class,
      // as we don't need to waste time fetching the cart if it isn't
      const isTrackingStorageItemExists = this.storage.getItem(TRACKING_KEY);
      if (isTrackingStorageItemExists) {
        clearAssociatedProduct();
        clearDiscount();
        this.storage.removeItem(TRACKING_KEY);
        this.cartToken = null;

        // Notify other sources about the cleanup
        window.dispatchEvent(
          new CustomEvent(this.cartTokenUpdatedEventName, {
            detail: { token: null },
          }),
        );
      }
    }
  }
}
