function isStorageAvailable(type: string) {
  let storage: Storage | undefined | null;
  try {
    storage = window[type as keyof Window];
    const x = '__storage_test__';
    storage!.setItem(x, x);
    storage!.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}
export default isStorageAvailable;
