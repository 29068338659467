/**
 * @description Requests shared between apps
 */
import {
  AppsInfo as AppsInfoAPIResponse,
  Offer,
  Product,
  REGISTERED_APPS,
  SmartProduct,
} from '../types';
import {
  ajaxApiRequestConfig,
  getCurrentLocale,
  getCurrentLocalePrefix,
  handleResponseDeprecated,
  parseJson,
  parseJsonDeprecated,
  timeoutFetch,
} from './utils';
import {
  APIError,
  IGNORE_TAGS,
  NetworkError,
  RecommendedProductsRequestConfig,
} from './types';
import { logInfo } from '../utils/logging';
import { Cart } from '@digismoothie/shared-candyrack';

async function getProduct(handle: string) {
  const path = window.location.pathname;
  const localePrefix = getCurrentLocalePrefix(path);

  let data = await parseJson(
    fetch(`${localePrefix}/products/${handle}.js`),
    true,
  );
  // Refetching the same product without locale path
  if (!data) {
    data = await parseJson(fetch(`/products/${handle}.js`), true);
  }

  return data;
}

function getCart() {
  return parseJsonDeprecated(
    fetch(`/cart.js?_=${new Date().getTime()}`),
  ) as Promise<Cart>;
}

/*
 * Generates query parameters for the "Add to Cart" fetch request.
 *
 * The current implementation formats the query parameters to be compatible with a specific
 * store setup, which uses a query string pattern such as:
 * ?items[][id]=${productVariantId}&items[][quantity]=${quantity}.
 *
 * This method dynamically replaces the placeholders with the provided product variant ID and quantity.
 *
 * Note:
 * - `productVariantId` is mandatory and should be a valid number representing the product variant.
 * - `quantity` is optional; if not provided, it defaults to '1'.
 *
 * Future Considerations:
 * If there arises a need to support multiple store setups with varying query string formats,
 * this function may need to be refactored into a more general solution. At present, this is
 * tailored to address a specific corner case.
 *
 * @param {number} productVariantId - The ID of the product variant to add to the cart.
 * @param {number} [quantity=1] - The quantity of the product variant to add to the cart.
 * @param {Record<string, string>} - Extra parameters.
 * @returns {string} The formatted query string with the specified product variant ID, quantity and dynamic params.
 */
function createAddToCartQueryParamsFromWindow(
  productVariantId: number,
  quantity?: number,
  properties?: Record<string, string> | null,
) {
  if (window.ADD_TO_CART_QUERY_STRING) {
    let queryParams = window.ADD_TO_CART_QUERY_STRING.replace(
      '${productVariantId}',
      productVariantId.toString(),
    ).replace('${quantity}', quantity ? quantity.toString() : '1');

    if (properties) {
      const propertiesQueryString = Object.keys(properties)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(properties[key])}`,
        )
        .join('&');

      if (propertiesQueryString) {
        queryParams += `&${propertiesQueryString}`;
      }
    }
    return queryParams;
  }
  return '';
}

function addToCart(
  productVariantId?: number,
  quantity?: number,
  properties?: Record<string, string> | null,
  isModifiedRequest: boolean = false,
) {
  if (!productVariantId) {
    return;
  }
  const customAddToCartQuery = createAddToCartQueryParamsFromWindow(
    productVariantId,
    quantity,
    properties,
  );
  if (customAddToCartQuery) {
    // when window.ADD_TO_CART_QUERY_STRING exists we fill all relevant body information as query params
    return parseJsonDeprecated(
      fetch(`/cart/add.js${customAddToCartQuery}`, {
        ...ajaxApiRequestConfig,
      }),
    );
  }

  let data: any = {
    quantity,
    id: productVariantId,
  };

  if (properties) {
    data.properties = properties;
  }

  if (isModifiedRequest) {
    data = window.CANDYRACK_MODIFY_UPGRADE_ADD_TO_CART_BODY(
      quantity as number,
      productVariantId,
      properties,
    );
    logInfo(
      `Add.js body modified ${JSON.stringify(data)}`,
      REGISTERED_APPS.CANDYRACK,
    );
  }

  return parseJsonDeprecated(
    fetch('/cart/add.js', {
      ...ajaxApiRequestConfig,
      body: JSON.stringify(data),
    }),
  );
}

async function removeFromCart(
  productVariantId?: number | string,
  qty?: number,
) {
  if (!productVariantId) {
    return;
  }
  const data = {
    quantity: qty ? qty : 0,
    id: String(productVariantId),
  };

  return parseJsonDeprecated(
    fetch(`/cart/change.js`, {
      ...ajaxApiRequestConfig,
      body: JSON.stringify(data),
    }),
  );
}

function distributeOffersToSmartProducts(
  products: Product[],
  smartOffers: Offer[],
): SmartProduct[] {
  let indexOffers = 0;
  let indexProducts = 0;
  return products.map((product) => {
    const productWithOffer = {
      ...product,
      offer: smartOffers[indexOffers],
    };
    if (
      indexProducts + 1 ===
      smartOffers[indexOffers].recommended_product_count
    ) {
      indexProducts = 0;
      indexOffers++;
    } else {
      indexProducts++;
    }
    return productWithOffer;
  });
}

function changeItems(productsToRemove: any) {
  const data = { updates: productsToRemove };
  return parseJsonDeprecated(
    fetch(`/cart/update.js`, {
      ...ajaxApiRequestConfig,
      body: JSON.stringify(data),
    }),
  );
}

function updateAttributes(attributes: any) {
  const data = {
    attributes,
  };

  return fetch(`/cart/update.js`, {
    ...ajaxApiRequestConfig,
    body: JSON.stringify(data),
  });
}

async function getAppsInfo(): Promise<
  AppsInfoAPIResponse | APIError | NetworkError
> {
  // REACT_APP_CUSTOMER_API_HOSTNAME will be empty string in the forked application
  const hostname = process.env.REACT_APP_CUSTOMER_API_HOSTNAME?.trim()?.length
    ? process.env.REACT_APP_CUSTOMER_API_HOSTNAME
    : process.env.REACT_APP_LAST_UPSELL_API_HOSTNAME;
  const shop = window.Shopify.shop;
  const response = await handleResponseDeprecated(
    fetch(`${hostname}api/apps-info/?shop=${shop}`),
  );

  return response as Promise<AppsInfoAPIResponse>;
}

async function getRecommendedProductsBase(
  productId: number,
  config: RecommendedProductsRequestConfig,
  ignoreTag: IGNORE_TAGS,
  limit: number,
) {
  // Will return empty string if window.CANDYRACK_DISABLE_LOCALE === true
  const localePrefix = getCurrentLocalePrefix(window.location.pathname);

  try {
    // First, we try to fetch intent='related' unless changed by window.CANDYRACK_IS_SMART_OFFER_RELATED_INTENT_PREFERRED
    let recommendedProducts = await getShopifyRecommendedProducts(
      localePrefix,
      productId,
      ignoreTag,
      window.CANDYRACK_IS_SMART_OFFER_RELATED_INTENT_PREFERRED
        ? 'related'
        : 'complementary',
    );

    if (recommendedProducts.length < limit) {
      // Second, if no products fetched, we try to fetch intent='complementary' unless changed by window.CANDYRACK_IS_SMART_OFFER_RELATED_INTENT_PREFERRED
      const secondIntentProducts = await getShopifyRecommendedProducts(
        localePrefix,
        productId,
        ignoreTag,
        window.CANDYRACK_IS_SMART_OFFER_RELATED_INTENT_PREFERRED
          ? 'complementary'
          : 'related',
      );
      recommendedProducts = addUniqueProducts(
        recommendedProducts,
        secondIntentProducts,
        limit,
      );
    }

    if (recommendedProducts.length < limit) {
      // Will return empty string if window.CANDYRACK_DISABLE_LOCALE === true
      const locale = getCurrentLocale(window.location.pathname);
      const fallbackProducts = await parseJsonDeprecated(
        fetch(
          `${config.hostname}api/${config.appPrefix}/smart-upsell-fallback?shop=${window.Shopify.shop}&lang=${locale}`,
        ),
      );
      recommendedProducts = addUniqueProducts(
        recommendedProducts,
        fallbackProducts,
        limit,
      );
    }

    return recommendedProducts;
  } catch (e) {
    logInfo(e);
    return [];
  }
}

function addUniqueProducts(
  existingProducts: Product[],
  newProducts: Product[],
  limit: number,
): Product[] {
  const uniqueProducts = [...existingProducts];
  const existingIds = new Set(existingProducts.map((p) => p.id));

  for (const product of newProducts) {
    if (!existingIds.has(product.id) && uniqueProducts.length < limit) {
      uniqueProducts.push(product);
      existingIds.add(product.id);
    }
    if (uniqueProducts.length >= limit) break;
  }

  return uniqueProducts;
}

export {
  updateAttributes,
  changeItems,
  removeFromCart,
  addToCart,
  getCart,
  getProduct,
  getAppsInfo,
  getRecommendedProductsBase,
  distributeOffersToSmartProducts,
};

async function getShopifyRecommendedProducts(
  localePrefix: string,
  productId: number,
  ignoreTag: IGNORE_TAGS,
  intent: string = 'related',
) {
  const response = await parseJsonDeprecated(
    timeoutFetch(
      `${localePrefix}/recommendations/products.json?product_id=${productId}&intent=${intent}`,
      3000,
    ),
  );
  const recommendedProducts = response?.products?.filter(
    (product: Product) => !product.tags.includes(ignoreTag),
  );
  return recommendedProducts;
}
