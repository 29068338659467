import isStorageAvailable from './storage-check';

type StorageType = 'localStorage' | 'sessionStorage';

function initializeMemoryStorage(): Record<string, string> {
  const handler = {
    get: function (obj: Record<string, string>, prop: string) {
      return prop in obj ? obj[prop] : null;
    },
  };
  return new Proxy({}, handler);
}

export class AppStorage implements Storage {
  private __storage: Record<string, string> | null;
  private __type: StorageType;

  get length(): number {
    return this.__storage
      ? Object.keys(this.__storage).length
      : window[this.__type].length;
  }

  constructor(storageType: StorageType = 'localStorage') {
    this.__type = storageType;
    this.__storage = !isStorageAvailable(storageType)
      ? initializeMemoryStorage()
      : null;
  }

  key(index: number): string | null {
    if (this.__storage) {
      const keys = Object.keys(this.__storage);
      if (keys.length <= index || index < 0) {
        return null;
      } else {
        return keys[index];
      }
    }
    return window[this.__type].key(index);
  }

  getItem(key: string): string | null {
    return this.__storage
      ? this.__storage[key]
      : window[this.__type].getItem(key);
  }

  clear(): void {
    if (this.__storage) {
      this.__storage = initializeMemoryStorage();
    }
    window[this.__type].clear();
  }

  removeItem(key: string): void {
    if (this.__storage) {
      delete this.__storage[key];
    }
    window[this.__type].removeItem(key);
  }

  setItem(key: string, value: string): void {
    if (this.__storage) {
      this.__storage[key] = value;
    } else {
      window[this.__type].setItem(key, value);
    }
  }
}

const customLocalStorage = new AppStorage();
const customSessionStorage = new AppStorage('sessionStorage');

export default customLocalStorage;
export { customSessionStorage };
